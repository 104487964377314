<script>
export default {
  props: {
    agencia: { type: Object }
  },
}
</script>

<template>
<div id="agencia">
  <p>Sigla: {{ agencia.agencia_codigo }}</p>
  <p>agencia: {{ agencia.agencia_digito}} </p>
  <p>ID_empresa: {{ agencia.empresa_id}} </p>
  <p>ID_banco: {{ agencia.banco_id}} </p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ agencia.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ agencia.updated_at }}</b-badge></p>
</div>
</template>
