<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
//import { http } from '../../../../helpers/easyindustriaapi/config';
import Widget from "./widget.vue";

export default {
  //components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Incluir Agencia',
      agencia:{
        agencia_codigo : 1,
        agencia_digito: null,
        banco_id: 1,
      },
      name_banco: null
    }
  },

props:{
  List_bancos1 : {Array}
},

  components:{
    Multiselect,
    Widget
  },

  validations() {
    return {
      agencia:{
         agencia_codigo : {required},
         banco_id: {required},
        
         
      }
    }
  },
  
  mounted() {
      this.setNewTitle();
  },
  
  methods: {
    
    banco_cod_id(banco_selecionado){
      console.log(banco_selecionado)
       this.agencia.banco_nome = banco_selecionado.banco_nome
       this.agencia.banco_id = banco_selecionado.id;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit('doPost', this.agencia);
    },
  },
    
}
</script>

<template>
<div class="card">
  <div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <Widget :nome="agencia.banco_nome" :digito="agencia.agencia_digito"  />
          <h4>Agencia Banco</h4>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label for="tipo_digito_id" class="col-form-label">Codigo</label>
                  <div class="col">
                    <input v-model="agencia.agencia_codigo" class="form-control text-leftt" type="text" placeholder="digite a agencia" id="agencia">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label for="tipo_digito_id" class="col-form-label">digito</label>
                  <div class="col">
                    <input  v-model="agencia.agencia_digito" class="form-control text-left" type="text" placeholder="digite o digito" id="digito">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label for="tipo_digito_id" class="col-form-label">Banco_ID</label>
                  <div class="col">
                    <!-- {{ agencia.banco_id }} -->
                    <multiselect v-model="name_banco"  :options="List_bancos1" :multiple="false"
                        :close-on-select="true" :clear-on-select="true" :preserve-search="true"
                        optionLabel="banco_nome" label="banco_nome" :track-by="'id'"
                        placeholder="Buscar ou selecionar produtos..." @select="banco_cod_id($event)">
                        <!-- o Event manda o um objeto produto para o metodo -->
                        <template slot="option" slot-scope="{ option }">
                          {{ option.banco_nome }}
                        </template>
                      </multiselect>
                    
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            
          </div>
          <div class="form-row form-group" role="group">
            
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>
  <div class="card-body" >
    <div class="col-md-12 mt-10">
      <button class="btn btn-primary"
       @click="setPost">Gravar</button>
    </div>
  </div> 
</div>
</template>

<style>

#oi{
 left:240px;
  
}

#oi2{
 left:240px;
  
}

.oi3{
 margin-left:350px;
  
}

.oi4{
 margin-left:450px;
  
}

#but{
  margin-left:450px;
  margin-top:40px;

}

</style>