<script>
import appConfig from "@/app.config";
import { authEasyMethods, notificationMethods } from '@/state/helpers';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header';
import List from "./list.vue";
import Insert from "./insert.vue";
import Edit from "./edit.vue";
import { http } from '../../../../helpers/easyindustriaapi/config';

export default {
  page: {
    title: 'Agencia',
    meta: [{ name: 'description', content: appConfig.description }]
  },
  components: { Layout, PageHeader,Insert,List,Edit},
  data() {
    return {
      currentAgencia:{},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Financeiro",
          href: "/",
          active: true,
        },
        {
          text: "Agencia",
          href: "/financeiro/agenciaBanc",
          active: true,
        }
      ],
      //tipoMovimento: undefined,
      //tiposMovimento: [],

      agencia: undefined,
      T_agencia: [],
      titleBody: 'agencia',
      editando: false,
      inserindo: false,
      List_bancos1:[],
    }
  },

  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user')); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));// para saber a emrpessa atual;
    // storage esta no navegador em aplucation;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return (!this.inserindo && !this.editando);
    },
  },
  mounted() {
    this.getData();
    this.List_bancos();
   
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },

    async List_bancos(){

      this.onLoader();

      try {
        let response = await http.get('/banco?empresa_id='+this.currentEmpresa.id);

        if (response.status === 200) {
          this.List_bancos1 = response.data;
          console.log(this.List_bancos1)
          setTimeout(() => {
            this.offLoader();
          }, 2000);// tempo para carregar a tela;
        } else {
          this.List_bancos1 = []; // zera o array;
          this.makeToast('danger', 'Erro ao carregar lista');
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast('danger', 'Destino URL não encontrado!');
          } else {
            this.makeToast('danger', error.response);
          }
        }
      }

    },
    
    async getData() {
      this.onLoader();

      try {
        let response = await http.get('/agencia?empresa_id='+this.currentEmpresa.id);

        if (response.status === 200) {
          this.T_agencia = response.data;
          setTimeout(() => {
            this.offLoader();
          }, 2000);// tempo para carregar a tela;
        } else {
          this.T_agencia = []; // zera o array;
          this.makeToast('danger', 'Erro ao carregar lista');
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast('danger', 'Destino URL não encontrado!');
          } else {
            this.makeToast('danger', error.response);
          }
        }
      }
    },
    setAgencia(agencia) {
      this.currentAgencia = agencia;
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(oldAgencia) {
      this.setAgencia(oldAgencia);
      this.editando = !this.editando;
    },
    
    async doPost(agencia) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id

      agencia.empresa_id = this.currentEmpresa.id;
      agencia.user_id = this.currentUser.id;
      console.log(agencia);

      this.onLoader();

      try {
        let response = await http.post('/agencia?empresa_id='+this.currentEmpresa.id, agencia);
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast('success', 'Registro incluído');
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast('danger', 'Destino URL não encontrado!');
          } else {
            this.makeToast('danger', error);
          }
        }
      }

      
    },

    async doPut(agencia) {
      this.onLoader();

      try {
        let response = await http.put('/agencia/'+agencia.id+'?empresa_id='+this.currentEmpresa.id, agencia);
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.makeToast('success', 'Registro alterado');
            this.getData();
            this.editando = false;
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast('danger', 'Destino URL não encontrado!');
          } else {
            this.makeToast('danger', error);
          }
        }
      }
    },
    async doDelete(agencia) {
      this.onLoader();

      try {
        let response = await http.delete('/agencia/'+agencia.id+'?empresa_id='+this.currentEmpresa.id, agencia);
        if (response) {
          
          if (response.status === 200) {
            this.getData();
            this.offLoader();
            this.makeToast('warning', 'Registro excluído');
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast('danger', 'Destino URL não encontrado!');
          } else {
            this.makeToast('danger', error);
          }
        }
      }
    },
    
    
    back() {
      this.inserindo = false;
      this.editando  = false;
      this.titleBody = 'Agencia';
    },
  },
}
</script>

<template>
<Layout>
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Agencia</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div v-if="!isHide" class="card-body">
          

           <Edit v-if="editando"
           :empresa="currentEmpresa"
           :oldAgencia="currentAgencia"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />

          <Insert v-if="inserindo"
           :List_bancos1="List_bancos1"
           @newTitle="changeHomeTitle"
           @doPost="doPost"
          />

         
        </div>

        <List
         v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listAgencia="T_agencia"
         :hide="isHide"
         @edit="edit"
         @newTitle="changeHomeTitle"
         @setAgencia="setAgencia"
         @doDelete="doDelete"
        />
        
      </div>
    </div>
  </div>
</Layout>
</template>