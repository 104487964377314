<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";

export default {
  props: { 
      oldAgencia: { type: Object, required: true }
    },
 // components: { Multiselect },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Banco',
      agencia: {},
      
    }
  },
  validations: {
    agencia: {
         agencia_codigo : {required},
        agencia_digito: {required},
    }
  },
  mounted() {
    this.setNewTitle();
    this.agencia = this.oldAgencia;
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log('Gravou');
      this.$emit('doPut', this.agencia);
    },
  },
    
}
</script>

<template>

<div class="card">
  <div class="card-body">
    <i v-if="loader.get" class="fa fa-spinner fa-5x fa-spin text-success text-center"></i>
    <form id="form-tipo-movimento" role="form" class="form-horizontal">
      <b-card no-body class="mb-1">
        <b-card-body>
          <h4>Agencia</h4>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_unidade_id" class="col-form-label">ID</label>
                  <div class="col">
                    <input  disabled v-model="agencia.id" class="form-control text-left" type="text" placeholder="id" id="tipo_unidade_id">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_codigo_id" class="col-form-label">Código</label>
                  <div class="col">
                    <input  v-model="agencia.agencia_codigo" class="form-control text-leftt" type="text" placeholder="digite o Codigo" id="codigo">
                  </div>
                </div>
              </div>
          </div>
          <div class="form-row form-group" role="group">
            <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="tipo_digito_id" class="col-form-label">digito</label>
                  <div class="col">
                    <input  v-model="agencia.agencia_digito" class="form-control text-left" type="text" placeholder="digite " id="digito">
                  </div>
                </div>
              </div>
          </div>
        </b-card-body>
      </b-card>
    </form>
  </div>

  <div class="card-body">
    <div class="col-md-12 mt-10">
      <button class="btn btn-light"
       @click="setPut">Alterar</button>
    </div>
  </div>
</div>
</template>